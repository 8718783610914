@import url("https://fonts.googleapis.com/css2?family=Overpass:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Aquire";
  src: url("../fonts/AquireBold.woff2") format("woff2"),
    url("../fonts/AquireBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aquire";
  src: url("../fonts/AquireRegular.woff2") format("woff2"),
    url("../fonts/AquireRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aquire";
  src: url("../fonts/AquireLight.woff2") format("woff2"),
    url("../fonts/AquireLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: "Overpass", sans-serif;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  overflow-y : auto !important;
  font-family: "Overpass", sans-serif;
}

a {
  color: #4190b7 !important;
  text-decoration: none;
}
.chip{
  white-space: normal !important;
}
a:hover {
  color: #4190b7;
  text-decoration: none;
}

p {
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #748085;
  font-weight: 400;
}


.tooltip-inner{
  background-color: white;
    color: red;
    border: 1px solid red;
}
.tooltip-arrow::before {
  border-bottom-color: red !important;
}

.form-control {
  cursor: pointer;
}

.text-accent {
  color: #4190b7;
}

.btn-check:focus + .btn,
.btn:focus,
.form-check-input:focus,
.accordion-button:focus,
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}

.ls-3 {
  letter-spacing: 0.35px;
}

.ls-4 {
  letter-spacing: 0.4px;
}

.form-control:focus,
.form-select:focus {
  outline: 0;
  box-shadow: none;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  isolation: unset !important;
}
.profile_rfcard .nav-tabs .nav-link.active {
  overflow:auto ;
}
/* Font Sizes */
.pagination{
  width:100%;
  margin:20px;
}
.pagination li{
  padding-left: 10px;
  padding-right: 10px;
}
.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.form-control::placeholder {
  color: #b3c2c9;
  opacity: 1;
}

label {
  color: #748085;
}

.text-primary {
  color: #4190b7 !important;
}

.text-body-light {
  color: #b3c2c9;
}

.border-primary {
  border-color: #d2e0e5 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.form_block .regsiterrf .form-control {
  border: 1px solid #b3c2c9 ;
}

/* Welcome CSS */

.welcome .carousel-indicators [data-bs-target] {
  width: 80px;
  margin-right: 0px;
  margin-left: 0px;
}

.css-b62m3t-container .css-106o0pu-Menu {
  top: 26px !important;
  background: #fff;
  width: 100%;
  position: absolute !important;
  z-index: 600;
}
.css-b62m3t-container {
  position: relative;
}

.welcome .carousel-control-next-icon,
.welcome .carousel-control-prev-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 14px;
  position: absolute;
  bottom: 22px;
  background-color: #ffffff38;
  border-radius: 50%;
  padding: 19px;
}

.welcome button.carousel-control-prev {
  right: 80px;
  left: auto;
}

.welcome .carousel-indicators {
  position: absolute;
  right: 18em;
  bottom: 10px;
  left: auto;
  padding: 0;
  margin-right: 0;
  margin-left: 0;
  z-index: 11;
}

.welcome .carousel-caption {
  position: absolute;
  right: auto;
  top: 3%;
  left: 9%;
  text-align: left;
  z-index: 11;
}

.welcome .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #0067cb;
  /* Old browsers */
  background: -moz-linear-gradient(left, #0067cb 0%, #00debb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #0067cb 0%, #00debb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #0067cb 0%, #00debb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0067CB', endColorstr='#00DEBB', GradientType=1);
  /* IE6-9 */
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: contain;
}

.welcome .overlay-line {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  /* background-image: url(../img/lines.svg); */
  background-repeat: no-repeat;
  background-size: contain;
}

.welcome .carousel-control-next,
.welcome .carousel-control-prev {
  z-index: 11;
  opacity: 1;
}

.banner_block {
  padding-top: 12em;
}

button.btn.banner_btn {
  background-color: #dff0f8;
  border: 1px solid #dff0f8;
  color: #4190b7;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 20px;
  border-radius: 50px;
  padding: 10px 29px 5px;
  letter-spacing: 0.3px;
}

a.btn.banner_btn {
  background-color: #dff0f8;
  border: 1px solid #dff0f8;
  color: #4190b7;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 20px;
  border-radius: 50px;
  padding: 10px 29px 5px;
  letter-spacing: 0.3px;
}

.banner_block h2 {
  font-family: "Aquire";
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 5.95px;
}

.banner_block p {
  font-size: 18px;
  letter-spacing: 0.54px;
}

.welcome .carousel-item {
  height: 100vh;
}

.welcome .carousel-control-next-icon {
  background-image: url("../img/chevron_right.svg");
}

.welcome .carousel-control-prev-icon {
  background-image: url("../img/chevron_left.svg");
}

.welcome img.banner_img {
  height: 100%;
}

/* Login CSS */

section.login {
  width: 100%;
  height: 100vh;
  background-image: url(../img/login_graphic2.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: left center;
}

#login {
  width: 100%;
  height: 100vh;
  background-image: url(../img/login_graphic2.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: left center;
  /* background-attachment: fixed; */
  position: relative;
}

/* 
#login:after {
  content: "";
  position: absolute;
  background-image: url(../img/login_graphic2.svg);
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: left center;
  left: 0;
  bottom: 0;
  z-index: 999;
} */

body.logn {
  width: 100%;
  height: 100vh;
  /* background-image: url(../img/lines.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f7fbfd;
  background-position: right;
}
textarea{
  resize: none;
}
section.login .container-fluid,
section.login .container-fluid .row {
  height: 100%;
}

/* 
:root {
  --swiper-navigation-size: 0 !important;
} */

.login_outerWrapper {
  /* background-image: url(../img/lines.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f7fbfd;
  background-position: right;
}

body #root .logn {
  width: 100%;
  height: 100vh;
  /* background-image: url(../img/lines.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f7fbfd;
  background-position: right;
}

.login .form-control {
  border: 1px solid #b3c2c9;
  border-radius: 8px;
  height: 40px;
  /* padding: 2px 15px 0px; */
  background-color: transparent;
  border-right: 0px;
  font-size: 13px;
}

.login .input-group-text {
  border: 1px solid #b3c2c9;
  background-color: transparent;
  border-left: 0px;
  border-radius: 8px;
}

.form_block {
  padding-top: 3em;
  width: 25em;
  text-align: center;
  margin: 0px auto;
}

.common-registration.loginwithlinks {
  padding-top: 10px;
}

.loginwithlinks.job_actions.text-center {
  padding-top: 8em;
}
.links{
  /* background-color: transparent; */
  position: relative ;
  top: 40px ; 
}
 
.login_rtblock.position-relative.pt-4
  .d-lg-flex.d-md-flex.justify-content-between.mobile-display-none {
  align-items: center;
}

.newloginwithlinks {
  right: 10px;
  position: relative;
  bottom: 0;
  padding-top: 1.5em;
}
.loginwithlinks {
  /* position: absolute; */
  /* bottom: 0; */
  padding-top: 1.5em;
}

.loginwithlinks .d-lg-flex.d-md-flex.justify-content-between {
  justify-content: center !important;
  align-items: center;
  margin-top: -25px;
}
.login-webnative-wrapper {
  display: flex!important;
  justify-content: space-around!important;
}
.loginwithbtn {
  margin-left: 20px;
}

.loginwithlinks button.btn {
  color: transparent;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0px 4px 16px #4190b729;
  /* padding: 17px; */
  /* line-height: 0px; */
  width: 56px;
  height: 58px;
}

.loginwithlinks a.btn {
  color: transparent;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0px 4px 16px #4190b729;
  /* padding: 17px; */
  /* line-height: 0px; */
  width: 56px;
  height: 58px;
  position: relative;
}

.loginwithlinks a.btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.doctor_leftimg {
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  padding: 0px;
}

.overlay_content {
  position: absolute;
  top: 50%;
  padding: 0px 5em;
  right: 8em;
  transform: translate(0%, -50%);
}

.overlay_logo {
  position: relative;
  top: 41px;
  width: 100%;
  padding: 0px;
  right: 3em;
  text-align: center;
}

.registeras .card {
  border: 1px dashed #b3c2c9;
  background-color: #f7fbfd;
  padding: 8px 0px;
  border-radius: 10px;
}

.registeras .card.active {
  border: 1px solid #4190b7;
  position: relative;
}

.registeras .card.active:after {
  position: absolute;
  content: "";
  background-image: url(../img/selected_icon.svg);
  background-size: auto;
  background-repeat: no-repeat;
  width: 100%;
  top: -8px;
  height: 100%;
  background-position: top right;
  right: -4px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

form.otp_box input {
  display: inline-block;
  width: 45px;
  height: 45px;
  text-align: center;
  background-color: #f7fbfd;
  border: 1px solid #b3c2c9;
  border-radius: 5px;
  margin: 0px 7px;
}

/* Step Process Three */

.step {
  position: relative;
  min-height: 4em;
}

.step + .step {
  margin-top: 1.5em;
}

.step > div:first-child {
  position: static;
  height: 0;
}

.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}

.step.step-active {
  color: #4285f4;
}

.step.step-active .circle {
  background-color: #fff;
  box-shadow: 0 0 0 6px #ffffff2e;
  color: #fff;
  width: 20px;
  height: 20px;
}

.step.step-complete .circle {
  background-color: #fff;
  box-shadow: 0 0 0 6px #ffffff2e;
  color: #b1d4da;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.step.step-complete .circle::after,
.step.step-active .circle::after {
  left: 8px;
  top: -20px;
}

.circle {
  background: #b1d4da;
  position: relative;
  width: 16px;
  height: 16px;
  line-height: 1.5em;
  border-radius: 100%;
  color: #b1d4da;
  text-align: center;
  z-index: 11;
}

.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: -24px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 40px;
  width: 2px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: #b1d4da;
}

.step:last-child .circle:after {
  display: none;
}

.step .title {
  line-height: 1.5em;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.54px;
}

/* Step Process Three */

.loginlt {
  position: absolute;
  bottom: 8%;
  left: 9%;
  text-align: center;
}

.setup_profile .overlay_content {
  top: 50%;
  left: 0;
}

.card.edu_card {
  background-color: #effaff;
  border: 1px dashed #cfdfe5;
  border-radius: 10px;
  text-align: center;
  padding: 16px 0px;
}

.card.edu_card p {
  color: #152c37;
  margin: 0;
}

.profileinfo_block {
  padding-top: 6em;
  width: 53%;
  margin: 0px auto;
}

.trackingstep {
  padding-top: 5em;
}

.AddEducationLevel {
  padding-top: 9em;
  width: 54%;
  margin: 0px auto;
}

a.addlevel {
  border: 1px dashed #4190b7;
  border-radius: 7px;
  padding: 10px 13px 7px;
}

.levelcircle {
  position: relative;
}

.levelcircle:before {
  position: absolute;
  content: "";
  background-color: #bbd9e8;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -26px;
  top: 3px;
}

.levelcircle.active:after {
  position: absolute;
  content: "";
  border: 1px solid #bbd9e8;
  height: 100%;
  left: -22px;
  top: 20px;
}

.AddLevelCanvas.offcanvas-end {
  width: 358px;
}

label.skl_block {
  background-color: #4190b71a;
  border-radius: 5px;
  padding: 5px 15px 1px;
  font-size: 14px;
  color: #4190b7;
  font-weight: 600;
}

.PrerequisiteLevel {
  width: 91%;
  margin: 0px auto;
  padding-top: 1em;
}

.PreferenceLevel {
  width: 91%;
  margin: 0px auto;
  padding-top: 6em;
}

.AddSkillsBlock .btn-close {
  width: 2px;
  height: 2px;
  opacity: 1;
  position: relative;
  top: 3px;
}

.AddSkillsBlock {
  padding-bottom: 14px;
  overflow-x: auto;
}

.single_skills {
  padding: 6px 12px;
  border-radius: 8px;
}

.single_skills p {
  color: #212529;
  font-weight: 600;
}

.AddSkillsBlock .single_skills .btn-close {
  top: 4px;
}

.rect_prof .overlay_content {
  right: 14em;
}

.form_block .regsiterrf .form-select {
  border: 1px solid #b3c2c9 !important;
  border-radius: 8px;
}

.forgot_pass.form_block {
  padding-top: 14em;
}

/* Home CSS */

.rlmenu a.nav-link {
  border: 1px solid #d2dfe5;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  line-height: 22px;
  text-align: center;
  margin-left: 8px;
}

.lfmenu a.nav-link {
  font-size: 18px;
  font-weight: 500;
  padding: 0px 21px !important;
  color: #4190b7 !important;
  letter-spacing: 1.08px;
}

nav.navbar {
  background-color: #fcfeff !important;
  box-shadow: 0px 3px 16px #0091b412;
}

.lfmenu a.nav-link.active {
  position: relative;
}

.lfmenu a.nav-link.active:after {
  position: absolute;
  content: "";
  height: 3px;
  width: 22px;
  background-color: #4190b7;
  bottom: -22px;
  left: 21px;
}

.lfmenu a.nav-link.active {
  position: relative;
  color: #4190b7 !important;
}

body.home_block {
  background-color: #f7fbfd;
}

.rlmenu a.nav-link.lang_flag img {
  width: 26px;
  position: relative;
  top: -3px;
  left: -3px;
}

.job_card.card {
  background-color: #fff;
  box-shadow: 0px 4px 30px #0091b41a;
  border: 0px;
  border-radius: 10px;
  position: relative;
  width: 84%;
  margin: 0px auto;
  z-index: 100;
  margin-top: 35px !important;
}
#explore-job-card{
  margin-top: 50px !important;
}
hr {
  background-color: #eaedf7;
  opacity: 1;
}

.jobmatch_percent p {
  color: #009a12;
  font-weight: 700;
  font-size: 13px;
}

.job_card.card .card-body {
  padding: 34px;
  background-color: white;
  border-radius: 10px;
}

.jobmatch_percent {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 10px #009a2a67;
  border-radius: 23px;
  padding: 8px 19px 4px;
}

.job_bookmark {
  position: absolute;
  right: 22px;
  top: -12px;
}

.job_card.overlap_card {
  position: absolute;
  top: 78px;
  width: 52%;
  z-index: 22;
  text-align: center;
  margin: 0px auto;
  left: 0;
  right: 0;
}

.left-section{
}
.loginwithlinks.job_actions button.btn {
  margin: 0px 40px;
}

.job_card.overlap_card.third_card {
  top: 90px;
  width: 50%;
  z-index: 3;
}

.rlmenu.rlmenu_mobile {
  display: none;
}

.search_header .dropdown-toggle::after,
.lang_dropdown .dropdown-toggle::after {
  display: none;
}

#SearchModal .modal-content {
  padding: 14px 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b433;
  border-radius: 15px;
  border: 0;
  width: 379px;
}

#SearchModal .input-group-text {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #eaedf7;
  border-radius: 0;
  padding: 0px;
}

#SearchModal .form-control {
  border: 0;
  border-bottom: 1px solid #eaedf7;
  border-radius: 0;
}

#SearchModal .modal-dialog {
  position: absolute;
  right: 10em;
  top: -15px;
}

#SearchModal button.btn-close {
  font-size: 10px;
  position: relative;
  top: 8px;
  color: #000;
  opacity: 1;
}

a.nav-link.search_modal {
  position: relative;
}

.lang_dropdown button.dropdown-item.active {
  background-color: #e5f2f8;
  color: #4190b7;
}

.lang_dropdown ul.dropdown-menu {
  min-width: 13em;
  padding: 3px;
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0091b426;
  border: 0;
  border-radius: 15px;
  top: 57px;
}

.lang_dropdown button.dropdown-item {
  border-radius: 10px;
  position: relative;
}

.lang_dropdown ul.dropdown-menu.dropdown-menu-end.show:before {
  position: absolute;
  content: "";
  right: 11px;
  top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #80c2b2 transparent;
}

#SearchModal span.divide_line {
  background-color: #f5f5f5;
  width: 2px;
  height: 11px;
  position: relative;
  right: -6px;
  top: 12px;
}

#offcanvasSearchFilter.offcanvas {
  z-index: 9999;
}
#offcanvasPreference.offcanvas {
  z-index: 9999;
  width: 52rem;
}


#offcanvasSearchFilter .offcanvas-header,
#offcanvasSidebarMenu .offcanvas-header,
#offcanvasNotification .offcanvas-header {
  border-bottom: 1px solid #e7e7e7;
}

#offcanvasPreference .offcanvas-header,
#offcanvasSidebarMenu .offcanvas-header,
#offcanvasNotification .offcanvas-header {
  border-bottom: 1px solid #e7e7e7;
}

#offcanvasSidebarMenu .offcanvas-body,
#offcanvasSidebarMenu .offcanvas-header {
  padding: 1rem 2rem;
}

#offcanvasSidebarMenu a.menu_title {
  color: #152c37;
  text-decoration: none;
}

#offcanvasSidebarMenu a.menu_title img {
  margin-right: 10px;
}

li.lang_bg {
  background-image: url(../img/select_language_bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  padding: 16px 0px !important;
  margin-bottom: 9px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.lang_dropdown li {
  padding: 0px 12px;
}

img.check_lang {
  position: absolute;
  right: 8px;
  top: 12px;
}

.modal-backdrop,
.offcanvas-backdrop {
  background: transparent
    linear-gradient(241deg, #80c2b2 0%, var(--unnamed-color-4190b7) 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(241deg, #80c2b2 0%, #4190b7 100%) 0%
    0% no-repeat padding-box;
}

.offcanvas {
  box-shadow: 0px 4px 16px #4190b729;
  border: 0 !important;
}

#offcanvasNotification .itemside {
  position: relative;
  display: flex;
  width: 100%;
}

#offcanvasNotification .itemside .aside {
  position: relative;
  flex-shrink: 0;
}

#offcanvasNotification .itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}

#offcanvasNotification .itemside .aside img,
.notification_card .itemside .aside img {
  background-color: #4190b71c;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  padding: 7px;
}

.notification_card .itemside p.title {
  color: #748085;
  font-weight: 400;
}

#offcanvasNotification .itemside .info span {
  color: #030833;
}

.active_notification {
  background-color: #f2f8fa;
  padding: 15px 14px;
}

.normal_notification {
  padding: 15px 14px;
}

#offcanvasNotification .offcanvas-body {
  padding: 0;
}

.card.profile_lfcard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b41a;
  border: 0;
}

.profile_cardimg img {
  background: #e7f3f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #4190b77e;
  border-radius: 50%;
  padding: 4px;
  height: 120px;
  width: 120px;
}

.profile_cardimg {
  margin-top: -6em;
}

.profile_actionbtn button.btn {
  color: transparent;
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0px 4px 16px #4190b729;
  width: 52px;
  height: 52px;
  margin: 0px 14px;
}

.card.profile_lfcard .card-body {
  padding: 34px 39px;
  height: 100%;
}

.profile_rfcard .nav-tabs .nav-item.show .nav-link,
.profile_rfcard .nav-tabs .nav-link.active {
  color: #4190b7;
  background-color: transparent;
  border-color: #d2ebf7;
  font-size: 16px;
  letter-spacing: 0.48px;
  position: relative;
}

.profile_rfcard .nav-tabs .nav-link.active:after {
  position: absolute;
  content: "";
  height: 3px;
  width: 22px;
  background-color: #4190b7;
  bottom: 0;
  left: 0;
}

.profile_rfcard .nav-tabs .nav-link {
  border: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 16px;
  letter-spacing: 0.48px;
  padding: 0px 56px 12px 0px;
  color: #748085;
}

.profile_rfcard .tab-content {
  padding: 28px 0px 0px;
}

p.profile_role {
  color: #222222;
  font-weight: 600;
}

a.profile_role {
  color: #006cff;
  font-weight: 600;
}

.profile_rfcard .levelcircle:before {
  background-color: #99d5e3;
}

.profile_rfcard .levelcircle.active:after {
  border: 1px solid #99d5e3;
}

.profilebtn_block .dropdown-menu {
  background-image: url(../img/select_language_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-width: 18rem;
  text-align: center;
  border: 0;
  box-shadow: 0px 4px 16px #0091b458;
  padding: 22px 0px;
  border-radius: 10px;
  top: 12px !important;
}

button.btn.open_actionbtn {
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  width: 74px;
  height: auto;
  border-radius: 20px;
  border: 1px solid #ffffff41;
  margin: 0px 6px;
}

button.btn.open_actionbtn.active {
  background-color: #ffffff26;
  border: 1px solid #fff;
}

.profilebtn_block ul.dropdown-menu.dropdown-menu-end.show:before {
  position: absolute;
  content: "";
  right: 19px;
  top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #80c2b2 transparent;
}

.outlineaction_btn button.btn.open_actionbtn {
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid #ffffff41;
  margin: 0px 6px;
}

#ProfileVideosModal .modal-dialog {
  max-width: 100%;
}

#ProfileVideosModal .modal-content {
  background-color: transparent;
  border: 0;
}

#ProfileVideosModal .modal-header {
  background-color: transparent;
  border-bottom: 0;
}

#ProfileVideosModal .modal-body {
  background-color: #000;
}

#ProfileVideosModal .video_controls {
  position: absolute;
  top: 24px;
  right: 20px;
}

.explore_card {
  background-image: url(../img/explore_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 35px 0px;
  width: 100%;
}

.explore_img {
  text-align: right;
  position: relative;
  top: 56px;
  right: 29px;
}

.explore_banner {
  margin-left: 50px;
}

.card.explorejob_card {
  width: 192px;
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b426;
  border-radius: 9px 10px 10px 10px;
  border: 0;
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 30px;
}

.card.explorejob_card p {
  color: #152c37;
  letter-spacing: 0.21px;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}


.card.explorejob_card:after {
  position: absolute;
  content: "";
  background-image: url(../img/arrow_green.svg);
  background-repeat: no-repeat;
  width: 14px;
  height: 100%;
  right: -14px;
  top: 30%;
}

.card.explorejob_card:before {
  position: absolute;
  content: "";
  background: transparent linear-gradient(180deg, #80c2b2 0%, #4190b7 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0000000d;
  width: 148px;
  height: 57px;
  z-index: -1;
  border-radius: 10px;
  top: -2px;
  left: -2px;
}

.breadcrumbs_block {
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #0091b412;
  padding: 12px 0px;
}

li.breadcrumb-item a {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.96px;
}

.breadcrumb-item.active {
  color: #030833;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.96px;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #b0c2c8;
}

.card.settings_card {
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b419;
  border-radius: 15px;
  border: 0;
  /* margin-top: 1.5em !important; */
}

.settings_card p {
  color: #152c37;
  margin: 0;
}

.form-switch .form-check-input {
  width: 44px;
  height: 26px;
}

.settings_card .card-body {
  padding: 28px;
}

/* Chat CSS */

.chat_block .nav-tabs .nav-link.active {
  background-color: #fcfeff;
  border: 0;
  border-top-right-radius: 42px;
  color: #030833;
  border-top-left-radius: 0px;
  margin: 0;
}

.chat_block .nav-tabs .nav-link {
  width: 100%;
  background-color: #4090b7;
  color: #fff;
  border-top-left-radius: 42px;
  border-top-right-radius: 42px;
  margin-left: -30px;
}

.chat_block li.nav-item {
  width: 40%;
}

.chat_block .tab-content {
  background-color: #fcfeff;
  padding: 16px 29px;
}

.chat_lfpanel .itemside,
.chat_message .itemside {
  position: relative;
  display: flex;
}

.chat_lfpanel .itemside .aside,
.chat_message .itemside .aside {
  position: relative;
  flex-shrink: 0;
}

.chat_lfpanel .itemside .info,
.chat_message .itemside .info {
  padding-left: 15px;
  padding-right: 7px;
}

.chat_lfpanel .itemside .aside img,
.chat_message .itemside .aside img {
  border-radius: 50%;
  width: auto;
  height: auto;
  padding: 7px;
}

.chat_lfpanel .itemside span,
.chat_message .itemside span {
  color: #030833;
}

.chat_lfpanel p.title,
.chat_message p.title {
  font-size: 16px;
  color: #030833;
  font-weight: 600;
  letter-spacing: 0.21px;
}

.chat_role span {
  color: #748085;
  font-size: 14px;
  letter-spacing: 0.18px;
}

.chat_header {
  background-color: #fcfeff;
  border: 0;
  padding: 14px 26px 9px;
  border-top-left-radius: 8px;
  display: inline-block;
  border-top-right-radius: 72px;
  width: 44%;
  border-bottom-right-radius: 0px;
  box-shadow: 0px -15px 24px #0091b419;
}

.chat_header.active {
  margin-left: -22px;
  background-color: #4190b7;
  color: #fff;
  /* z-index: -1; */
  border-top-left-radius: 39px;
}

.chat_header a {
  color: #030833;
}

.chat_header.active a {
  color: #fff;
}

.chat_lfpanel {
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b419;
  border-top-right-radius: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  padding: 25px 18px;
  min-height: 100%;
  height: 100%;
}

.chat_message .card {
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b419;
  border-radius: 15px;
  border: 0;
}

.chat_message .card .card-body {
  padding: 30px 22px;
}

.chat_time p {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #0091b441;
  border-radius: 5px;
  display: inline-block;
  padding: 2px 9px;
  font-size: 14px;
}

.msg_received .card {
  background: #f7fbfd 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b429;
  border-radius: 10px 10px 10px 0px;
  display: inline-block;
  margin-bottom: 9px;
}

.msg_received .card .card-body {
  padding: 9px 24px;
}

.msg_received .card .card-body p {
  color: #030833;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.24px;
}

.msg_received p.chatreceive_time {
  font-size: 12px;
  color: #152c37;
  position: relative;
  font-weight: 500;
  left: 22px;
}

.msg_received.msg_send .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b429;
  border-radius: 10px 10px 0px 10px;
  position: relative;
}

/* .msg_received.msg_send {
    float: right;
} */

.msg_received.msg_send p.chatreceive_time {
  text-align: right;
  left: -22px;
}

.type_message .input-group {
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b419;
  border-radius: 10px;
}

.type_message input.form-control {
  padding: 12px 18px;
  border: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.type_message .input-group-text {
  padding: 12px;
  background-color: #ffffff;
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.hide {
  display: none;
}

.linco_dropdown.dropstart .dropdown-toggle::before {
  display: none;
}

.linco_dropdown button.btn.dropdown-toggle {
  padding: 0px;
}

.linco_dropdown ul.dropdown-menu.show {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #0091b433;
  border-radius: 5px;
  border: 0;
  top: 24px !important;
}

.profilebtn_block.jobprofile_block .dropdown-menu {
  background-color: #fff;
  background-image: none;
  min-width: 7em;
  padding: 6px 6px 5px;
  border-radius: 5px;
}

.skills_jobs h4 {
  font-size: 14px;
  color: #748085;
}

.skills_jobs p {
  font-size: 14px;
  color: #030833;
}

.skills_jobs p span {
  font-size: 12px;
  color: #4190b7be;
}

.skills_progress .progress {
  height: 0.5px;
}

.filled_skill {
  background-color: #99d5e3;
  height: 2px;
  border-radius: 2px;
  width: 36px;
}

.empty_skill {
  background-color: #f0ebe9;
  height: 2px;
  border-radius: 2px;
  width: 42px;
}

.row.skills_progress {
  margin-left: -3px;
  margin-top: 12px;
}

.profilejobmatch_percent {
  text-align: center;
  margin-bottom: 14px;
}

.profilejobmatch_percent p {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 0px 10px #009a2a67;
  border-radius: 23px;
  padding: 8px 19px 4px;
  color: #009a12;
  font-weight: 700;
  font-size: 13px;
  display: inline-block;
  text-align: center;
}

.loginwithlinks.editprofile_block {
  position: absolute;
  right: 1em;
  bottom: 0;
}
#left-btn-basicInfo{
  position: absolute;
  left: 10px;
  bottom: 0;
}

.upload_profileimg .profle_upload {
  border-radius: 50%;
  width: 94px;
  height: 94px;
  border: 0;
  position: relative;
  text-align: center;
  margin: 0px auto;
  margin-top: -92px;
  box-shadow: 0px 4px 16px #0091b419;
}

.profle_circle {
  position: relative;
}

.profle_circle img.upload-button {
  position: absolute;
  bottom: -37px;
  right: -26px;
}

.plan_title {
  padding: 9px 0px 12px;
}

.plan_feature {
  background: #edf6fa 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 12px 2px;
  margin: 0px 28px 26px;
}

.pricing_block .card-body {
  padding: 28px 0px 0px;
}

.pricing_header {
  background-image: url(../img/free_trail_bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  border: 0;
  box-shadow: 0px 4px 16px #0091b412;
  border-radius: 15px;
}

.pricing_header .card-body {
  padding: 18px 0px;
}

.pricing_header .card-body p.light {
  letter-spacing: 0.24px;
  color: #ffffff;
  opacity: 0.5;
}

.pricing_plans .plan_title {
  padding: 9px 0px 28px;
}

.pricing_plans .plan_feature {
  padding: 28px 2px;
  margin-bottom: 0px;
}

.pricing_plans .light_feature {
  padding: 28px 2px;
}

.light_feature button.btn {
  background: transparent linear-gradient(259deg, #80c2b2 0%, #4190b7 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b433;
  border: 2px solid #ffffff;
  border-radius: 21px;
  width: 73%;
  color: #fff;
  padding: 7px 9px;
}

.pricing_plans .card {
  min-height: 509px;
  margin-bottom: 2em;
}

.pricing_block .card.pricing_lf {
  top: 9em;
}

.pricing_plans .card {
  min-height: 509px;
  margin-bottom: 2em;
  margin-top: 42px;
}

#HomeUpgrade .modal-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b426;
  border-radius: 20px;
  border: 0;
  padding: 15px 45px;
}

#HomeUpgrade button.btn.btn-outline-primary {
  border: 1px solid #0091b4;
  border-radius: 19px;
  color: #0091b4;
  padding: 6px 22px;
  font-size: 14px;
}

#HomeUpgrade button.btn.btn-primary {
  border: 1px solid #0091b4;
  background-color: #0091b4;
  border-radius: 19px;
  color: #fff;
  padding: 6px 22px;
  font-size: 14px;
}

#HomeUpgrade button.btn {
  margin: 6px 12px 0px;
}

#HomeUpgrade p {
  color: #030833;
}

#HomeUpgrade .modal-dialog {
  max-width: 442px;
}

.settings_userblock .form-control {
  padding: 9px 18px;
  color: #b3c2c9;
  background-color: transparent;
  border: 1px solid #cfdfe5;
  border-radius: 10px;
  margin-right: 26px;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.settings_userblock.input-group .btn {
  position: relative;
  z-index: 2;
  background: transparent linear-gradient(247deg, #80c2b2 0%, #4190b7 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  border: 1px solid;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 9px 28px;
}

.settings_userblock.input-group {
  width: 75%;
  margin: 28px auto 0px;
}

.blocked_list table tr th {
  color: #748085;
  font-weight: 400;
}

.blocked_list tbody tr td {
  color: #030833;
}

.blocked_list tbody tr {
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b419;
  border-radius: 5px;
}

.blocked_list td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.blocked_list td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.blocked_list tbody tr td {
  color: #030833;
  padding: 10px 14px !important;
}

button.btn.gradient_btn {
  background: transparent linear-gradient(262deg, #80c2b2 0%, #4190b7 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  color: #fff;
  padding: 9px 17px 7px;
  letter-spacing: 0.48px;
}

.delete_profile .form-check-input:checked {
  background-color: #4190b7;
  border-color: #4190b7;
  width: 20px;
  height: 20px;
}

.delete_profile .form-check-input {
  background-color: #fff;
  border-color: #d2dde2;
  width: 20px;
  height: 20px;
}

.faq .accordion-item {
  margin-bottom: 20px;
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b419;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.faq .accordion-button:not(.collapsed) {
  color: #030833;
  background-color: #fff;
  box-shadow: none;
}

.faq .accordion-body {
  color: #748085;
  font-weight: 400;
}

.faq .accordion-button {
  color: #030833;
}

.contact_lf {
  position: relative;
  top: 5em;
  padding: 0px 28px;
}

.contact_us {
  background-image: "none";
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 26px;
  border: 0;
  background-color: white;
  padding: 58px 100px;
  background-position: center;
}

.matched_jobs .nav-pills .nav-link.active {
  background: transparent linear-gradient(253deg, #80c2b2 0%, #4190b7 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b426;
  border-radius: 5px;
  color: #fff;
}

.matched_jobs .nav-pills .nav-link {
  background: #fcfeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #0091b426;
  border-radius: 5px;
  margin-top: 10px;
  color: #748085;
}

.matched_jobs li.nav-item {
  margin-right: 18px;
}

.subscribe_price.pricing_plans .card {
  min-height: 509px;
  margin-bottom: 2em;
  margin-top: 42px;
  width: 20%;
  margin-right: 14px;
}

.subscribe_price.pricing_plans .plan_title {
  padding: 25px;
}

.subscribe_price.pricing_plans .card-body {
  padding: 0px;
}

.popular_plan p {
  background: #80c2b2 0% 0% no-repeat padding-box;
  border-radius: 13px;
  padding: 3px 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.72px;
  position: absolute;
  right: 24%;
  text-align: center;
  top: -11px;
}

.pricing_block.subscribe_price_block .card.pricing_lf {
  top: 14em;
}

.create_job_tracking .step.step-active .circle {
  background-color: #80c2b2;
  color: #80c2b2;
  width: 30px;
  box-shadow: 0px 4px 16px #0091b436;
  height: 30px;
  border: 4px solid #fff;
}

.create_job_tracking .step.step-complete .circle {
  background-color: #80c2b2;
  color: #80c2b2;
  width: 30px;
  box-shadow: 0px 4px 16px #0091b436;
  height: 30px;
  border: 4px solid #fff;
}

.create_job_tracking .step .title {
  line-height: 1.5em;
  font-weight: 500;
  color: #152c37;
  letter-spacing: 0.27px;
  font-size: 18px;
}

.create_job_tracking .circle {
  background: #fff;
  color: #fff;
  box-shadow: 0px 4px 16px #0091b427;
  width: 22px;
  height: 22px;
}

.create_job_tracking .circle:after {
  top: -15px;
  right: 50%;
  left: 50%;
  height: 37px;
  background-color: #fff;
  box-shadow: 0px 1px 6px #0091b426;
}

.create_job_tracking .step.step-complete .circle::after,
.create_job_tracking .step.step-active .circle::after {
  left: 10px;
  top: -11px;
}

.create_job_tracking.trackingstep {
  padding-top: 3em;
}

.create_job_tracking .step-complete .circle:after {
  background-color: #80c2b2;
}

.step.inactive .circle {
  left: 2px;
}

.swith_btn.btn-group.btn-toggle {
  background-color: #f7fafc;
  border: 1px solid #e7f0f4;
  border-radius: 10px;
  width: 100%;
}

.swith_btn button.btn.btn-lg.active {
  margin: 3px;
  border-radius: 10px;
  background: transparent linear-gradient(257deg, #80c2b2 0%, #4190b7 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  border: 1px solid;
}

.swith_btn button.btn {
  font-size: 14px;
  /* padding: 5px 23px; */
}

button.btn.job_open_btn {
  background: #4190b71a 0% 0% no-repeat padding-box;
  border-radius: 19px;
  color: #4190b7;
  padding: 5px 30px;
}

.create_job_card_profile .upload_profileimg .profle_upload {
  margin-top: 0px;
}

.input-group.country-code-select select {
  width: 80px;
  flex: none;
}

.tutorial_otrWrapper .swiper .swiper-slide img {
  display: block;
  width: 100%;
}

.tutorial_otrWrapper .swiper .swiper-pagination {
  bottom: 50px;
}
.tutorial_otrWrapper
  .swiper
  .swiper-pagination
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  background: #fff;
}

.tutorial_otrWrapper .swiper .swiper-button-next,
.swiper-button-prev {
  color: #fff !important;
}

/* register */
.right-register{
  height: 100vh;
  overflow: auto;
}
/* no record found */
.no-record{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}
.no-record-style{
  font-size: 22px;
  font-weight: bold;
  color: red;
}
/* login screen resetup as per native start */
.mobile-display-none {
  display: block;
}
.btn-desktop{
  display: block !important;
}
.desktop-display-none {
  display: none;
}


/* ************** */
.login-nativeBtn-wrapper .login-nativeBtn {
  font-family: "Overpass", sans-serif;
  font-size: 16px;
  letter-spacing: 0.48px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  display: block;
  border: 0;
margin-bottom: 10px;
  width: 100%;
  padding: 10px 0px;
  background: #0067cb;
  background: -moz-linear-gradient(left, #0067cb 0%, #00d7bc 100%);
  background: -webkit-linear-gradient(left, #0067cb 0%, #00d7bc 100%);
  background: linear-gradient(to right, #0067cb 0%, #00d7bc 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0067CB', endColorstr='#80c2b2', GradientType=1);
  border-radius: 6px;
  text-transform: uppercase;
}
/* ***************** */
/* login screen resetup as per native end */

#edit-delete-btn{
  position: absolute;
  right: 5px;
  top: 10px;
}

.Card-button{
  position: absolute;
    display: flex;
    bottom: 20px;
    justify-content: center;
    transform: translate(-50%);
    left: 50%;
    z-index: 10000;
  
}
/* Small Devices */

@media only screen and (max-width: 767.98px) {
  .no-record{
    transform: translate(-50%,120%);
  }
  #edit-delete-btn{
    position: relative;
    top: 10px;
  }
  ul.profile-tabWrapper.nav.nav-tabs li {
    width: 50%;
  }

  .jobmatch_percent {
    left: 14px;
    top: 14px;
  }

  .loginwithlinks.job_actions.text-center {
    padding-top: 6em;
  }

  /* login screen resetup as per native start */

  body.logn .doctor_leftimg {
    height: auto;
  }

  body.logn .doctor_leftimg .overlay_content.text-center {
    transform: translate(0);
  }

  .mobile-register {
    display: block;
    margin: 50px 0 0 0;
  }

  .mobile-register p {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .mobile-display-none {
    display: none !important;
  }

  .login-webnative-wrapper {
    display: block;
  }

  .login-webnative-btnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
  }

  .login-webnative-btnWrapper a , button{
    display: flex;
    width: 150px;
    border: 1px solid #1976d2;
    border-radius: 8px;
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-evenly;
    padding: 8px 0;
  }

  .login-webnative-btnWrapper a img {
    margin: -4px 0 0 0 !important;
    padding: 0;
  }

  .login-webnative-btnWrapper a:nth-child(2) {
    border: 1px solid #3499bf;
  }

  .login-webnative-titleWrapper:after {
    position: absolute;
    border-bottom: 1px dotted #666666;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    content: "";
    z-index: 0;
  }

  .login-webnative-titleWrapper {
    width: 180px;
    margin: 0 auto 20px auto;
    position: relative;
  }

  .login-webnative-wrapper p {
    font-family: "Overpass", sans-serif;
    font-size: 16px;
    letter-spacing: 0.48px;
    color: #748085;
    font-weight: 400;
    text-align: center;
    display: block;
    position: relative;
    background: #f7fbfd;
    z-index: 600;
    width: 66%;
    margin: 0 auto;
  }

  .login-nativeBtn-wrapper {
    display: block;
    margin: 0 0 10px 0;
  }

  .login-nativeBtn-wrapper .login-nativeBtn {
    font-family: "Overpass", sans-serif;
    font-size: 16px;
    letter-spacing: 0.48px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    display: block;
    border: 0;

    width: 100%;
    padding: 10px 0px;
    background: #0067cb;
    background: -moz-linear-gradient(left, #0067cb 0%, #00d7bc 100%);
    background: -webkit-linear-gradient(left, #0067cb 0%, #00d7bc 100%);
    background: linear-gradient(to right, #0067cb 0%, #00d7bc 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0067CB', endColorstr='#80c2b2', GradientType=1);
    border-radius: 6px;
    text-transform: uppercase;
  }

  /* login screen resetup as per native end */

  /* .d-md-flex {
    display: flex !important;
    margin-top: -25px;
  } */
  /* .nav {
  } */
  .explore_card {
    padding: 35px 0px !important;
    margin: 0;
  }

  .breadcrumbs_block {
    position: relative;
    z-index: 80;
  }

  #login,
  section.login {
    background-image: none;
  }

  .banner_block {
    padding-top: 3em;
  }
  .banner_block h2 {
    font-size: 34px;
  }
  .banner_block p {
    padding: 0px 16px 0px 0px;
  }
  .welcome .carousel-indicators {
    right: 12em;
  }
  .welcome .carousel-indicators [data-bs-target] {
    width: 42px;
  }
  .welcome button.carousel-control-prev {
    right: 59px;
  }
  .form_block {
    width: 100%;
    padding-top: 3em;
  }
  .overlay_content {
    position: relative;
    top: 0;
    padding: 0px 7px;
    right: 0;
  }
  .doctor_leftimg {
    background: #0067cb;
    background: -moz-linear-gradient(left, #0067cb 0%, #80c2b2 100%);
    background: -webkit-linear-gradient(left, #0067cb 0%, #80c2b2 100%);
    background: linear-gradient(to right, #0067cb 0%, #80c2b2 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0067CB', endColorstr='#80c2b2', GradientType=1);
    padding: 20px 0px 7px;
  }
  #login {
    background-image: none;
  }
  .loginwithlinks {
    padding-top: 2em;
  }
  .setup_profile .overlay_content {
    top: 0;
    left: auto;
    padding: 0px 21px;
  }
  .trackingstep {
    padding-top: 2em;
  }
  .loginlt {
    position: relative;
    bottom: 8%;
    left: 0;
    text-align: center;
  }
  .profileinfo_block {
    padding-top: 1em;
    width: 94%;
    margin: 0px auto;
    padding-bottom: 2em;
  }
  .AddEducationLevel {
    padding-top: 1em;
    width: 80%;
    margin: 0px auto;
    padding-bottom: 2em;
  }
  .job_card.card {
    width: 100%;
  }
  .job_card.overlap_card {
    top: 60px;
    width: 85%;
  }
  .job_card.overlap_card.third_card {
    top: 66px;
    width: 74%;
    z-index: 3;
  }
  .loginwithlinks.job_actions {
    padding-top: 12rem;
    padding-bottom: 2rem;
  }
  nav.navbar {
    z-index: 99;
  }
  ul.rlmenu {
    display: none;
  }
  .navbar-brand img {
    margin-top: 12px;
  }
  .lfmenu li.nav-item {
    margin-bottom: 14px;
  }
  .lfmenu li.nav-item a.nav-link {
    padding: 0 !important;
    margin: 0 !important;
  }
  .lfmenu a.nav-link.active:after {
    bottom: -4px;
    left: 0;
  }
  ul.lfmenu {
    margin-top: 18px;
  }
  .rlmenu.rlmenu_mobile {
    display: block;
    position: absolute;
    top: 6px;
    z-index: 999;
    right: 13px;
  }
  .rlmenu.rlmenu_mobile li.nav-item {
    display: inline-block;
  }
  .rlmenu a.nav-link.lang_flag img {
    left: 0px;
  }
  #SearchModal .modal-dialog {
    position: relative;
    right: 0;
    top: 0;
  }
  #SearchModal .modal-content {
    width: 100%;
  }
  .card.explorejob_card {
    width: 100%;
  }
  /* .card.explorejob_card:before {
    width: 249px;
  } */
  .card.explorejob_card:before {
    width: 100%;
  }
  .chat_header {
    width: 50%;
  }
  .profile_cardimg {
    margin-top: 0;
  }
  .card.profile_lfcard .card-body {
    padding: 34px 19px;
  }
  .profile_actionbtn button.btn {
    margin: 0px 5px;
  }
  .profile_rfcard .nav-tabs .nav-link {
    padding: 22px 13px 8px 0px;
  }
  .profile_rfcard {
    padding: 0px 0px !important;
  }
  .row.skills_progress {
    margin-bottom: 14px;
  }
  .loginwithlinks.editprofile_block {
    position: relative;
    right: 0;
    bottom: 0;
    text-align: right;
  }
  .pricing_block .card.pricing_lf {
    top: 0;
  }
  .contact_us {
    background-image: none;
    background-color: #fff;
    padding: 0;
    margin-bottom: 2em;
  }
  .contact_lf {
    position: relative;
    top: 0;
    padding: 0px 12px 39px !important;
  }
  .pricing_block.subscribe_price_block .card.pricing_lf {
    top: 0em;
  }
  .subscribe_price.pricing_plans .card {
    width: 100%;
  }
  .AddSkillsBlock {
    padding-bottom: 14px;
    overflow-y: auto;
  }
  .PreferenceLevel {
    padding-top: 2em;
  }
  .AddSkillsBlock .skill_col {
    margin-bottom: 11px;
  }
  .rect_prof .overlay_content {
    right: -1em;
  }
  .forgot_pass.form_block {
    padding-top: 4em;
  }

  #offcanvasSidebarMenu,
  #offcanvasNotification {
    width: 300px;
  }

  #offcanvasSearchFilter {
    width: 21rem !important;
  }
  #offcanvasPreference{
    width: 21rem !important;
  }
}

/* Tablets */

@media only screen and (max-width: 991.98px) {
  /* login screen resetup as per native start */
  #Register-Btn {
    border-radius: 0;
  }
  .register-form {
    margin-bottom: 50px;
  }
  #registerBtn {
    margin-top: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
  }
  body.logn .doctor_leftimg {
    height: auto;
  }

  body.logn .doctor_leftimg .overlay_content.text-center {
    transform: translate(0);
  }

  .mobile-register {
    display: block;
    margin: 50px 0 0 0;
  }

  .mobile-register p {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .mobile-display-none {
    display: none !important;
  }

  .login-webnative-wrapper {
    display: block;
  }

  .login-webnative-btnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    justify-content: space-evenly;
  }

  .login-webnative-btnWrapper a ,button{
    display: flex;
    width: 150px;
    border: 1px solid #1976d2;
    border-radius: 8px;
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-evenly;
    padding: 8px 0;
  }

  .login-webnative-btnWrapper a img {
    margin: -4px 0 0 0 !important;
    padding: 0;
  }

  .login-webnative-btnWrapper a:nth-child(2) {
    border: 1px solid #3499bf;
  }

  .login-webnative-titleWrapper:after {
    position: absolute;
    border-bottom: 1px dotted #666666;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    content: "";
    z-index: 0;
  }

  .login-webnative-titleWrapper {
    width: 180px;
    margin: 0 auto 20px auto;
    position: relative;
  }

  .login-webnative-wrapper p {
    font-family: "Overpass", sans-serif;
    font-size: 16px;
    letter-spacing: 0.48px;
    color: #748085;
    font-weight: 400;
    text-align: center;
    display: block;
    position: relative;
    background: #f7fbfd;
    z-index: 600;
    width: 66%;
    margin: 0 auto;
  }

  .login-nativeBtn-wrapper {
    display: block;
    margin: 0 0 10px 0;
  }

  .login-nativeBtn-wrapper .login-nativeBtn {
    font-family: "Overpass", sans-serif;
    font-size: 16px;
    letter-spacing: 0.48px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    display: block;
    border: 0;

    width: 100%;
    padding: 10px 0px;
    background: #0067cb;
    background: -moz-linear-gradient(left, #0067cb 0%, #00d7bc 100%);
    background: -webkit-linear-gradient(left, #0067cb 0%, #00d7bc 100%);
    background: linear-gradient(to right, #0067cb 0%, #00d7bc 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0067CB', endColorstr='#80c2b2', GradientType=1);
    border-radius: 6px;
    text-transform: uppercase;
  }

  /* login screen resetup as per native end */

  #login,
  section.login {
    background-image: none;
  }
  .profile_rfcard .nav-tabs .nav-link {
    padding-right: 40px;
  }

  .doctor_leftimg {
    background: #0067cb;
    background: -moz-linear-gradient(left, #0067cb 0%, #80c2b2 100%);
    background: -webkit-linear-gradient(left, #0067cb 0%, #80c2b2 100%);
    background: linear-gradient(to right, #0067cb 0%, #80c2b2 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0067CB', endColorstr='#80c2b2', GradientType=1);
    padding: 20px 0px 7px;
  }
  #login {
    background-image: none;
  }
  .form_block {
    width: 100%;
    padding-top: 3em;
  }
  .overlay_content {
    position: relative;
    top: 0;
    padding: 0px 7px;
    right: 0;
  }
  .setup_profile .overlay_content {
    top: 0;
    left: auto;
    padding: 0px 21px;
    transform: translate(0%, 0%);
  }
  .trackingstep {
    padding-top: 2em;
  }
  .loginlt {
    position: relative;
    bottom: 8%;
    left: 0;
    text-align: center;
  }
  .profileinfo_block {
    padding-top: 1em;
    width: 94%;
    margin: 0px auto;
    padding-bottom: 2em;
  }
  .AddEducationLevel {
    padding-top: 1em;
  }
  ul.rlmenu {
    display: none;
  }
  nav.navbar {
    z-index: 99;
  }
  .navbar-brand img {
    margin-top: 12px;
  }
  .lfmenu li.nav-item {
    margin-bottom: 14px;
  }
  .lfmenu li.nav-item a.nav-link {
    padding: 0 !important;
    margin: 0 !important;
  }
  .lfmenu a.nav-link.active:after {
    bottom: -4px;
    left: 0;
  }
  ul.lfmenu {
    margin-top: 18px;
  }
  .rlmenu.rlmenu_mobile {
    display: block;
    position: absolute;
    top: 6px;
    z-index: 999;
    right: 13px;
  }
  .rlmenu.rlmenu_mobile li.nav-item {
    display: inline-block;
  }
  .rlmenu a.nav-link.lang_flag img {
    left: 0px;
  }
  .row.skills_progress {
    margin-bottom: 14px;
  }
  .loginwithlinks.editprofile_block {
    position: relative;
    right: 0;
    bottom: 0;
    text-align: right;
    padding-top: 2em;
  }
  .pricing_block .card.pricing_lf {
    top: 0;
  }
  .contact_us {
    background-image: none;
    background-color: #fff;
    padding: 0;
    margin-bottom: 2em;
  }
  .contact_lf {
    position: relative;
    top: 0;
    padding: 0px 28px;
  }
  .pricing_block.subscribe_price_block .card.pricing_lf {
    top: 0em;
  }
  .subscribe_price.pricing_plans .card {
    width: 100%;
  }
  .loginwithlinks {
    padding-top: 2em;
  }
  .rect_prof .overlay_content {
    right: -1em;
  }
  .forgot_pass.form_block {
    padding-top: 4em;
  }
}

/* Dekstop 1024 */

@media only screen and (max-width: 1024px) {
  .login-nativeBtn-wrapper .login-nativeBtn {
    z-index: 9999;
  }

  section.login,
  #login {
    background-position: -32em center;
    background-size: 100%;
  }
  .linkedIButton{
    display: flex;
    width: 150px;
    border: 1px solid #1976d2;
    border-radius: 8px;
    font-family: "Overpass", sans-serif;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-evenly;
    padding: 8px 0;
  }
  input[name="service-city"] {
    border: 2px solid orange;
    border-radius: 10px 10px 10px 10px;
    font-size: 18px;
    padding: 5px;
    height: 35px;
    width: 350px;
  }
  
}
